<template>
  <b-card
    no-body
    class="box-shadow-card"
  >
    <b-card-body>
      <b-media
        no-body
        style="align-items: center"
      >
        <b-media-aside class="mr-1">
          <b-img
            width="28"
            :src="require('@/assets/images/app-icons/address/Location.png')"
          />
        </b-media-aside>
        <b-media-body>
          <h5 class="mb-0">
            {{ address.address }}
          </h5>
        </b-media-body>
      </b-media>
      <b-card-text class="mb-0">
        <p
          v-if="address.address_2"
          class="m-0 mt-2"
        >
          {{ $t('Address 2') }}: {{ address.address_2 }}
        </p>
        <p class="m-0">
          {{ $t('Name') }}: {{ address.name }}
        </p>
        <p class="m-0">
          {{ $t('Email') }}: {{ address.email }}
        </p>
        <p class="m-0">
          {{ $t('Phone number') }}: {{ address.phone }}
        </p>
        <p class="m-0">
          {{ $t('City/State/Country') }}: {{ address.city }}, {{ address.state }}, {{ address.country }}
        </p>
        <div class="d-flex justify-content-between wrapper-responsive-address">
          <p class="mb-0">
            {{ $t('Zip/Pincode') }}: {{ address.pin_code }}
          </p>
          <div
            class="d-flex justify-content-end"
            style="margin-top: 5px;"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="text-white"
              @click="$emit('deliver-here', address)"
            >
              <feather-icon
                v-if="address.pickup_location == selectedAddress"
                icon="CheckSquareIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              {{ $t('Use this address') }}
            </b-button>
          </div>
        </div>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BMedia, BMediaAside, BMediaBody, BImg, BCardText, BButton, BCardBody } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BImg,
    BMedia,
    BMediaAside,
    BCardBody,
    BMediaBody,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    address: {
      type: Object,
      default: () => {},
    },
    selectedAddress: {
      type: String,
      default: '',
    },
  },
}
</script>
